.resizer {
  width: 10px;
  height: 10px;
  border-radius: 50%; /*magic to turn square into circle*/
  background: white;
  border: 3px solid #F000B4;
	position: absolute;
}
.resizer.top-left {
  left: 0px;
  top: 0px;
  cursor: nwse-resize; /*resizer cursor*/
}
.resizer.top-right {
  right: 0px;
  top: 0px;
  cursor: nesw-resize;
}
.resizer.bottom-left {
  left: 0px;
  bottom: 0px;
  cursor: nesw-resize;
}
.resizer.bottom-right {
  right: 0px;
  bottom: 0px;
  cursor: nwse-resize;
}
.resizer-top {
  border-top: 1px solid #F000B4E0;
}
.resizer-right {
  border-right: 1px solid #F000B4E0;
  bottom: 0px;
}
.resizer-bottom {
  border-bottom: 1px solid #F000B4E0;
}
.resizer-left {
  border-left: 1px solid #F000B4E0;
  bottom: 0px;
}

.scaletext-wrapper {
  display: flex;
}
