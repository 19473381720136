.popover-header {
	padding: .5rem .75rem;
	margin-bottom: 0;
	font-size: 1rem;
	background-color: #ffffff;
	border-bottom: 1px solid #ffffff;
	border-top-left-radius: calc(.3rem - 1px);
	border-top-right-radius: calc(.3rem - 1px);
}
.popover {
	border: 1px solid rgba(240,0,180,.8);
}
