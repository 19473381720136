@charset "UTF-8";
div[id^=font-picker] {
  position: relative;
  display: inline-block;
  width: 200px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
}
div[id^=font-picker] * {
  box-sizing: border-box;
}
div[id^=font-picker] p {
  margin: 0;
  padding: 0;
}
div[id^=font-picker] button {
  color: inherit;
  font-size: inherit;
  background: none;
  border: 0;
  outline: none;
  cursor: pointer;
}
div[id^=font-picker] .dropdown-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 35px;
  padding: 0 10px;
  background: #cbcbcb;
}
div[id^=font-picker] .dropdown-button:hover, div[id^=font-picker] .dropdown-button:focus {
  background: #bebebe;
}
div[id^=font-picker] .dropdown-button .dropdown-font-name {
  overflow: hidden;
  white-space: nowrap;
}
div[id^=font-picker] .dropdown-icon {
  margin-left: 10px;
}
@-webkit-keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
div[id^=font-picker] .dropdown-icon.loading::before {
  display: block;
  width: 10px;
  height: 10px;
  border: 2px solid #b2b2b2;
  border-top-color: #000000;
  border-radius: 50%;
  -webkit-animation: spinner 0.6s linear infinite;
          animation: spinner 0.6s linear infinite;
  content: "";
}
div[id^=font-picker] .dropdown-icon.finished::before {
  display: block;
  width: 0;
  height: 0;
  margin: 0 2px;
  border-top: 6px solid #000000;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  transition: transform 0.3s;
  content: "";
}
div[id^=font-picker] .dropdown-icon.error::before {
  content: "⚠";
}
div[id^=font-picker].expanded .dropdown-icon.finished::before {
  transform: rotate(-180deg);
}
div[id^=font-picker].expanded ul {
  max-height: 200px;
}
div[id^=font-picker] ul {
  position: absolute;
  z-index: 1;
  width: 100%;
  max-height: 0;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background: #eaeaea;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  -webkit-overflow-scrolling: touch;
}
div[id^=font-picker] ul li {
  height: 35px;
  list-style: none;
}
div[id^=font-picker] ul li button {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 10px;
  white-space: nowrap;
}
div[id^=font-picker] ul li button:hover, div[id^=font-picker] ul li button:focus {
  background: #dddddd;
}
div[id^=font-picker] ul li button.active-font {
  background: #d1d1d1;
}