.iconpickerggc-wrapper {
  position: relative;
  cursor: pointer;
}

.iconpickerggc-responsive {
  width: 100%;
}

.iconpickerggc {
  border: 1px solid #DDD;
  border-radius: 0px;
  background: #F8F8F8;
  position: relative;
  overflow: hidden;
}
.iconpickerggc .label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 28px 0 10px;
  font-size: 12px;
  line-height: 28px;
  color: #444;
  height: 28px;
  user-select: none;
}
.iconpickerggc .button {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 28px;
  height: 28px;
  line-height: 28px;
  background-color: #F8f8f8;
  color: #BBB;
  text-align: center;
  font: 0/0 a;
}
.iconpickerggc .button:after {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 0;
  height: 0;
  border: 4px solid transparent;
  border-top-color: #BBB;
  border-bottom: none;
}

.iconpickerggc-focus .iconpickerggc {
  border-color: #aaaaaa;
}

.iconpickerggc-hover .iconpickerggc {
  border-color: #c4c4c4;
}
.iconpickerggc-hover .iconpickerggc .button {
  color: #a2a2a2;
}
.iconpickerggc-hover .iconpickerggc .button:after {
  border-top-color: #a2a2a2;
}

.iconpickerggc-open {
  z-index: 1000;
}
.iconpickerggc-open .iconpickerggc {
  border-color: #c4c4c4;
}
.iconpickerggc-open .iconpickerggc-items {
  display: block;
}

.iconpickerggc-disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  user-select: none;
}

.iconpickerggc-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0;
}
.iconpickerggc-hide-select select {
  position: absolute;
  left: -100%;
}
.iconpickerggc-hide-select.iconpickerggc-is-native {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.iconpickerggc-hide-select.iconpickerggc-is-native select {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  border: none;
  z-index: 1;
  box-sizing: border-box;
  opacity: 0;
}

.iconpickerggc-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  font: 0/0 a !important;
  background: none !important;
}

.iconpickerggc-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important;
}

/* Items box */
.iconpickerggc-items {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: #F8F8F8;
  z-index: -1;
  box-shadow: 0 0 10px -6px;
}
.iconpickerggc-items .iconpickerggc-scroll {
  height: 100%;
  overflow-y: auto;
}
.iconpickerggc-above .iconpickerggc-items {
  top: auto;
  bottom: 100%;
}
.iconpickerggc-items .disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default !important;
  background: none !important;
  color: #666 !important;
  user-select: none;
}
.iconpickerggc-items .iconpickerggc-group .iconpickerggc-group-label {
  font-weight: bold;
  padding-left: 10px;
  cursor: default;
  user-select: none;
  background: none;
  color: #444;
}
.iconpickerggc-items .iconpickerggc-group.disabled li {
  filter: alpha(opacity=100);
  opacity: 1;
}
.iconpickerggc-items .iconpickerggc-group li {
  padding-left: 25px;
}
.iconpickerggc-items tr {
  margin:  0;
  border: none;
}
.iconpickerggc-items td {
  padding-top: 1px;
  padding-bottom: 1px;
  border: none;
}
.iconpickerggc-items td >div {
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.iconpickerggc-items table {
    border-collapse: separate;
}

.iconpickerggc-items td {
    border: 1px solid #fff;
}
.iconpickerggc-items td.selected {
    border: 1px solid #f000b4;
}


#sugg-select-container td.selected {
    border: 1px solid white;
}