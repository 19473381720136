.ugc-container {
	position:absolute;
	top:0;
	left:0;
	display:inline-block;
	/* https://github.com/bgrins/spectrum/issues/40 */
	z-index: 9999994;
	overflow: hidden;
}

/* Fix for * { box-sizing: border-box; } */
.ugc-container,
.ugc-container * {
	-webkit-box-sizing: content-box;
		 -moz-box-sizing: content-box;
					box-sizing: content-box;
}

.ugc-clear {
	display: none;
}

.ugc-hidden {
	display: none !important;
}

.ugc-container {
	border-radius: 0;
	background-color: #ECECEC;
	border: solid 1px #f0c49B;
	padding: 0;
}

/* Replacer (the little preview div that shows up instead of the <input>) */
.ugc-replacer {
	margin:0;
	overflow:hidden;
	cursor:pointer;
	padding: 4px;
	display:inline-block;
	*zoom: 1;
	*display: inline;
	border: solid 1px #000000;
	border-radius: 3px;
	background: #fff;
	color: #333;
	vertical-align: middle;
	margin-left: 8px;
}
.ugc-replacer:hover, .ugc-replacer.ugc-active {
	border-color: #F000B4;
	color: #111;
}
.ugc-replacer.ugc-disabled {
	cursor:default;
	border-color: silver;
	color: silver;
}
.ugc-dd {
	padding: 2px 0;
	height: 16px;
	line-height: 16px;
	float:right;
	font-size:10px;
}
.ugc-button-container {
	float: right;
}

.ugc-container {
	padding-bottom:0;
}

/* Buttons: http://hellohappy.org/css3-buttons/ */
.ugc-container button {
	background-color: #F000B4;
	border-style:none;
	border-radius: 3px;
	color: #fff;
	font-size: 12px;
	line-height: 1;
	font-weight: 600;
	padding: 5px 4px;
	text-align: center;
	vertical-align: middle;
}
.ugc-container button:hover {
	cursor: pointer;
}
.ugc-container button:active {
	border: 1px solid #aaa;
	border-bottom: 1px solid #888;
	-webkit-box-shadow: inset 0 0 5px 2px #aaaaaa, 0 1px 0 0 #eeeeee;
	-moz-box-shadow: inset 0 0 5px 2px #aaaaaa, 0 1px 0 0 #eeeeee;
	-ms-box-shadow: inset 0 0 5px 2px #aaaaaa, 0 1px 0 0 #eeeeee;
	-o-box-shadow: inset 0 0 5px 2px #aaaaaa, 0 1px 0 0 #eeeeee;
	box-shadow: inset 0 0 5px 2px #aaaaaa, 0 1px 0 0 #eeeeee;
}
.ugc-cancel {
	font-size: 12px;
	color: #f000b4 !important;
	margin: 0;
	padding:2px;
	margin-right: 5px;
	vertical-align: middle;
	text-decoration:none;
	text-transform: capitalize;
	align-self:center;
	cursor: pointer;
}
.ugc-cancel:hover {
	color: #f000b4 !important;
	text-decoration: none;
}