html, body, #root, #root>div{
    height: 100%;
    min-height: 100%;
    overflow: hidden;
}

.popover {
  max-width: 100%;
}

.iconpickerggc-wrapper {
    vertical-align: middle;
    margin-left: 8px;
}

.fieldpicker-paddy {
    margin-right: 7px !important;
    margin-bottom: 3px !important;
    word-break: break-all;  /* doesn't quite work */
}

/* New ones for new d3 graphs */
    .axis path,
    .axis line {
        fill: none;
        stroke: #000;
        shape-rendering: crispEdges;
    }    
    .tooltipGGC{
        text-anchor: middle;
        font-family: sans-serif;
        font-size: 10px;
        font-weight: normal;
        fill:black;
    }    
    .xlabelGGC {
        text-anchor: middle;
        font-family: sans-serif;
        font-weight: bold;
        fill:blue;
    }    
    .ylabelGGC {
        text-anchor: middle;
        font-family: sans-serif;
        font-weight: bold;
        fill:blue;
    }    
    .grplabelGGC {
        text-anchor: start;
        font-family: sans-serif;
        font-weight: bold;
        fill:blue;
    }    
    .overviewBar { 
        fill: gray;
        opacity: 0.5;
    }    
    .overviewBarNoFill { 
        fill: none;
	stroke: gray;
        opacity: 0.5;
    }    
    rect.overviewMover {
        stroke: red;
        stroke-opacity: .1;
        fill: lightSteelBlue;
        fill-opacity: .5;   
    }
    rect.ovBar {
        stroke: none;
        fill: gray;
        fill-opacity: .5;   
    }
    #fontSizer {
        position: absolute;
        visibility: hidden;
        height: auto;
        width: auto;
        white-space: nowrap;
    }
.genggc-tooltip-container {
    z-index: 500;
    pointer-events: none;
    position: absolute;
}
.genggc-tooltip {
    border-collapse: collapse;
    background-color: rgb(255, 255, 255);
    empty-cells: show;
    box-shadow: rgb(119, 119, 119) 7px 7px 12px -9px;
    opacity: 0.9;
    border-spacing: 0px;
}
.genggc-tooltip tr {
    border-width: 1px;
    border-style: solid;
    border-color: rgb(204, 204, 204);
    border-image: initial;
}
.genggc-tooltip td {
    font-size: 13px;
    background-color: rgb(255, 255, 255);
    padding: 3px 6px;
    border-left: 1px dotted rgb(153, 153, 153);
}
.genggc-tooltip th {
    font-size: 14px;
    background-color: rgb(255, 255, 255);
    padding: 3px 6px;
}
.genggc-tooltip td > span {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 6px;
}
.genggc-name {
    float: left;
}
.genggc-title {
    float: left;
}

.bia-palette-head {
    background: grey;
    color: white;
    text-align: center;
}

.bia-popover {
    z-index: 1000;
}
