.popover {
  max-width: 100%;
  box-shadow: 4px 8px #22222240;
}

.fontColorContainer {
  width: 50px;
}

.bs-popover-auto[x-placement^=right]>.arrow::before, .bs-popover-right>.arrow::before {
  left: 0;
  border-width: .5rem .5rem .5rem 0;
  border-right-color: rgb(240,0,180,1);
}
.bs-popover-auto[x-placement^=left]>.arrow::before, .bs-popover-left>.arrow::before {
  right: 0;
  border-width: .5rem 0 .5rem .5rem;
  border-left-color: rgb(240,0,180,1);
}
.bs-popover-auto[x-placement^=top]>.arrow::before, .bs-popover-top>.arrow::before {
  bottom: 0;
  border-width: .5rem .5rem 0;
  border-top-color: rgba(240,0,180,1);
}
.bs-popover-auto[x-placement^=bottom]>.arrow::before, .bs-popover-bottom>.arrow::before {
  top: 0;
  border-width: 0 .5rem .5rem .5rem;
  border-bottom-color: rgba(240,0,180,1);
}


input[type=range] {
  height: 25px;
  -webkit-appearance: none;
  margin: 0;
  width: 100%;
  margin-top:4px;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #F000B4;
  border-radius: 1px;
  border: 0px solid #000000;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #F000B4;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #F000B4
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #F000B4;
  border-radius: 1px;
  border: 0px solid #000000;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #F000B4;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #ffffff;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: #2497E3;
  border: 0px solid #000000;
  border-radius: 2px;
  box-shadow: 0px 0px 0px #000000;
}
input[type=range]::-ms-fill-upper {
  background: #F000B4;
  border: 0px solid #000000;
  border-radius: 2px;
  box-shadow: 0px 0px 0px #000000;
}
input[type=range]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #F000B4;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #FFFFFF;
  cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
  background: #F000B4;
}
input[type=range]:focus::-ms-fill-upper {
  background: #F000B4;
}


input.transg[type=range] {
  height: 25px;
  -webkit-appearance: none;
  margin: 0;
  width: 100%;
  margin-top:4px;
}
input.transg[type=range]:focus {
  outline: none;
}
input.transg[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background-image: linear-gradient(to right, white ,#F000B4);
  border-radius: 1px;
  border: 0px solid #000000;
}
input.transg[type=range]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #F000B4;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px;
}
input.transg[type=range]:focus::-webkit-slider-runnable-track {
  background-image: linear-gradient(to right, white ,#F000B4);
}
input.transg[type=range]::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background-image: linear-gradient(to right, white ,#F000B4);
  border-radius: 1px;
  border: 0px solid #000000;
}
input.transg[type=range]::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #F000B4;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #ffffff;
  cursor: pointer;
}
input.transg[type=range]::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  background-image: linear-gradient(to right, white ,#F000B4);
  border-color: transparent;
  color: transparent;
}
input.transg[type=range]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #F000B4;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #FFFFFF;
  cursor: pointer;
}
input.transg[type=range]:focus::-ms-fill {
  background-image: linear-gradient(to right, white ,#F000B4);
}

.editCtrlGGC {
  outline-color: #f000b4;
  outline-style: none;
}